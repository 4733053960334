import * as React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return <main>{children}</main>
}
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
